<template>
  <div class="card">
<div class="input-group">
    <label for="email">Email:</label>
    <input type="email" v-model="email" placeholder="Enter your email">
</div>
<div class="input-group">
  <label for="password">Password:</label>
  <div class="password-wrapper">
    <input :type="confirmPasswordType" v-model="password" placeholder="Enter your password">
    <el-button
      type="text"
      class="password-switch-3"
      v-if="confirmPasswordType == 'Password'"
      @click="confirmPasswordType = 'text'"
      icon="el-icon-view"
    ></el-button>
    <el-button
      type="text"
      class="password-switch-3"
      v-if="confirmPasswordType != 'Password'"
      @click="confirmPasswordType = 'Password'"
    >
      <img src="@/assets/img/eye-slash.svg" height="15" width="15" />
    </el-button>
  </div>
</div>
<button class="btn" @click="login">Login</button>
</div>
</template>
<script>
import { mapGetters, mapMutations  } from "vuex";
import NavigationHelper from "@/mixins/navigationHelper";
import axios from 'axios'
export default {
name: 'PublicLayout',
data() {
return {
  email: '',
password: '',
confirmPasswordType:"Password"
}
},
mixins: [
NavigationHelper,
],
computed:{
...mapGetters("errors", ["getErrors"]),
...mapGetters("auth", [
"getAuthenticationStatus",
"getAccountNotVerified",
"getAccountNotVerifiedEmail",
"getWorkspaceList",
"getAuthenticatedUser",
"getUserType",
"getErrorCode",
"getErrorData",
"getResendVerificationEmail",
]),
...mapGetters("sso",["getAuthenticatedSSOToken", "getIsUserLoggedIn"]),
},
async mounted () {
},
methods: {
...mapMutations("sso", ["setAuthenticatedSSOToken"]),
async login() {
let params ={
  email : this.email,
  password: this.password,
  redirect_url:'',
}
try {
let filesData = await axios.post(`https://ac-api.esigns.io/v1.0/auth/signin/client`, params);
this.$store.dispatch('sso/changeFlag', true);
localStorage.setItem('isLoggedIn', true);
this.setAuthenticatedSSOToken(filesData.data.code);
await this.$store.dispatch("sso/fetchTokenFromCode", { code :filesData.data.code});
this.$emit("closeSignInDialog");
} catch (error) {
this.$notify.error({
      title: "Error",
      message: "Invalid email or password. Please check and retry.",
    });
  console.error("Error occurred during login:", error);
}
}
}
};
</script>
<style scoped>
body {
display: flex;
justify-content: center;
align-items: center;
height: 100vh;
margin: 0;
}

.card {
width: 260px;
padding: 20px;
border: 1px solid #ccc;
border-radius: 5px;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.input-group {
margin-bottom: 20px;
}

.input-group label {
display: block;
margin-bottom: 5px;
}

.input-group input {
width: 100%;
padding: 8px;
border-radius: 5px;
border: 1px solid #ccc;
}

.input-group input:focus {
outline: none;
border-color: dodgerblue;
}

.btn {
width: 100%;
padding: 10px;
border: none;
border-radius: 5px;
background-color: dodgerblue;
color: #fff;
cursor: pointer;
}

.btn:hover {
background-color: #0a5cd1;
}
.password-wrapper {
  position: relative;
}

.password-wrapper .el-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 2px; /* Adjust as needed */
}
.password-wrapper .el-button:hover {
  border: none; /* Remove border on hover */
  background-color: transparent; /* Remove background color on hover */
  color: inherit; /* Inherit text color on hover */
}
</style>