<template>
  <section>
    <div v-if="errorPage" class="NotFound">
      <p>The link is inactive and no longer available</p>
    </div>
    <div v-else>
      <div v-if="getIframeUrlInfo && getIframeUrlInfo.url_type == 'PRIVATE'">
        <dialog-component
          title="Authentication"
          :visible="signInPopUp"
          :isShowFooter="false"
          :containerWidth="getIsMobile ? '100%' : '26%'"
          :showClose="false"
          ><div
            style="padding: 10px 10px 10px -20px; margin: 15px 10px 15px -1px"
          >
            <iFrameSignin v-on:closeSignInDialog="closeSignIn"></iFrameSignin>
          </div>
        </dialog-component>
      </div>
      <div class="public-view" v-if="showRouterView">
        <router-view></router-view>
      </div>
    </div>
  </section>
</template>

<script>
import appConfig from "@/config/app";
import { mapGetters } from "vuex";
import NavigationHelper from "@/mixins/navigationHelper";
import iFrameSignin from "@/views/iFrameSignin";
export default {
  name: "PublicLayout",
  data() {
    return {
      current_route: "",
      loading: false,
      baseURL: window.location.origin + this.$route.path,
      logoutBaseURL: window.location.origin + "/signin",
      serverBaseURL: appConfig.AUTHFAST_BASE_URL,
      code: "",
      loading_text: "Signing In....",
      failed: false,
      email: "",
      signInPopUp: false,
      nextRoute: "",
      errorPage: false,
      showRouterView: false,
    };
  },
  components: {
    iFrameSignin,
  },
  mixins: [NavigationHelper],
  computed: {
    ...mapGetters("errors", ["getErrors"]),
    ...mapGetters("auth", [
      "getAuthenticationStatus",
      "getAccountNotVerified",
      "getAccountNotVerifiedEmail",
      "getWorkspaceList",
      "getAuthenticatedUser",
      "getUserType",
      "getErrorCode",
      "getErrorData",
      "getResendVerificationEmail",
    ]),
    ...mapGetters("sso", [
      "getCodeExchangeData",
      "getCodeExchangeStatus",
      "getSSOExistedUserData",
    ]),
    ...mapGetters("sso", ["getAuthenticatedSSOToken", "getIsUserLoggedIn"]),
    ...mapGetters("iFrame", ["getIframeUrlInfo"]),
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
  },
  async mounted() {
    await this.isIframe();
    if (this.getIframeUrlInfo == null) {
      this.errorPage = true;
    }
    if (this.getIframeUrlInfo?.url_type == "PRIVATE") {
      if (this.getIsUserLoggedIn == false) {
        this.signInPopUp = true;
      }
    }
    this.$store.dispatch("sso/changeFlag");
    this.loading = true;
    this.failed = false;
    this.current_route = window.location.href;
    let ssoKey =
      this.$route.params && this.$route.params.ssoAuth
        ? this.$route.params.ssoAuth
        : null;
    if (ssoKey) {
      this.loading_text = "Verifying...";
      if (this.getIframeUrlInfo != null) {
        await this.$store.dispatch("sso/fetchTokenFromCode", {
          code: this.getIframeUrlInfo?.tokenData,
        });
      }
      this.showRouterView = true;
      if (
        this.getCodeExchangeStatus &&
        this.getCodeExchangeData &&
        this.getCodeExchangeData.status_code == "EXISTED_USER_LOGIN_SUCCESS"
      ) {
        this.currentWorkspace = this.getAuthenticatedUser.current_workspace;
        await this.fetchUserData();
        if (
          this.currentWorkspace.plan_type != "INDIVIDUAL" &&
          this.currentWorkspace.user_type
        ) {
          await this.getCompanyUserType();
        }
        this.isResent = false;
        await this.$store.dispatch("auth/fetchProfile");
      } else if (
        this.getCodeExchangeStatus &&
        this.getCodeExchangeData.status_code == "NEW_USER"
      ) {
        this.$router.push({ path: "/sso-signup" });
      } else {
        this.loading = false;
        this.failed = true;
      }
    }
    await this.checkUrlType();
  },
  methods: {
    async fetchUserData() {
      this.loading = true;
      await this.$store.dispatch(
        "auth/fetchUsersWithEmail",
        this.getAuthenticatedUser.email
      );
      this.loading = false;
      if (this.getWorkspaceList) {
        this.userWorkspaceList = this.getWorkspaceList;
        let hasIndividual = this.getAuthenticatedUser.workspace.findIndex(
          (u) => u.plan_type == "INDIVIDUAL"
        );
        if (hasIndividual != -1) {
          let individualWorkspace = {};
          if (this.getAuthenticatedUser.workspace[hasIndividual].company_id) {
            this.$set(
              individualWorkspace,
              "_id",
              "" + this.getAuthenticatedUser.workspace[hasIndividual].company_id
            );
          } else {
            this.$set(individualWorkspace, "_id", "0");
          }
          this.$set(individualWorkspace, "name", "INDIVIDUAL");
          this.userWorkspaceList[hasIndividual]["company_id"] =
            individualWorkspace;
          if (this.currentWorkspace.plan_type == "INDIVIDUAL") {
            if (this.getAuthenticatedUser.workspace[hasIndividual].company_id) {
              this.currentWorkspace["company_id"] =
                "" +
                this.getAuthenticatedUser.workspace[hasIndividual].company_id;
            } else {
              this.currentWorkspace["company_id"] = "0";
            }
          }
        }
        this.$store.commit("auth/setActiveWorkspace", this.currentWorkspace, {
          root: true,
        });
        this.$store.commit("auth/setWorkspaceList", this.userWorkspaceList, {
          root: true,
        });
      } else {
        this.userWorkspaceList = [];
      }
    },
    async getCompanyUserType() {
      try {
        await this.$store.dispatch(
          "auth/getUserTypeById",
          this.currentWorkspace.user_type
        );
        if (this.getUserType) {
          console.log(this.getUserType);
        }
      } catch (err) {
        console.log(err);
        this.$notify.error({
          title: "Error",
          message: err,
        });
      }
    },
    async closeSignIn() {
      this.signInPopUp = false;
      this.$store.dispatch("sso/changeFlag", true);
    },
  },
};
</script>

<style>
.NotFound {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(
    41,
    156,
    233,
    0.8
  ); /* Red background color with transparency */
  color: #fff; /* White text color */
  padding: 20px;
  border-radius: 5px;
}
</style>
<style scoped>
.public-view {
  margin-top: 1rem;
}
</style>
